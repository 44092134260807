<section class="overflow-y-auto h-full px-8">
  <div class="flex justify-between">
    <h1 class="block title-t1 mt-6 grey-1000">{{'recordList.records' | translate}}</h1>
    <app-button-secondary *ngIf="!isGuest" (click)="openTemplateModal()" [disabled]="limit?.step === 'in-limit'" [matTooltip]="limit?.text || ''" data-test="newRecord" class="mt-6">
      {{'buttons.newRecord' | translate}}
    </app-button-secondary>
  </div>

  <div class="p-3">
    <div class="filter flex justify-between items-center">
      <div class="flex mt-6 link space-x-2">
        <div class="flex link space-x-6 mr-4 mb-4">
          <p [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{view: VIEWS_TYPE.MAIN, sort: undefined, columns: undefined}" [class.grey-1000]="selectedView === VIEWS_TYPE.MAIN" data-test="mainViewButton">{{'recordList.views.main' | translate}}</p>
          <p [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{view: VIEWS_TYPE.TAXES, sort: undefined, columns: undefined}" [class.grey-1000]="selectedView === VIEWS_TYPE.TAXES" data-test="taxesViewButton">{{'recordList.views.taxes' | translate}}</p>
          <p [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{view: VIEWS_TYPE.TRANSPORT, sort: undefined, columns: undefined}" [class.grey-1000]="selectedView === VIEWS_TYPE.TRANSPORT" data-test="transportViewButton">{{'recordList.views.transports' | translate}}</p>
        </div>
      </div>
      <mat-form-field appearance="fill" class="w-2/3 space-x-1 mt-5">
        <input matInput [placeholder]="'recordList.filters.filter' | translate" #input data-test="recordSearch">
        <mat-icon svgIcon="search" class="p-4" matPrefix></mat-icon>
      </mat-form-field>
    </div>

    <div class="w-full flex justify-end items-center space-x-2">
      <div class="flex link space-x-2 mr-4 mb-4">
        <p [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{status: 'active'}" data-test="activeRecordsButton" routerLinkActive="grey-1000">{{'recordList.filters.open' | translate}}</p>
        <p [routerLink]="[]" queryParamsHandling="merge" [queryParams]="{status: 'archived'}" data-test="archivedRecordsButton" routerLinkActive="grey-1000">{{'recordList.filters.processed' | translate}}</p>
      </div>

      <mat-form-field class="caption-c1">
        <mat-select [formControl]="selectFilterControl" [placeholder]="'recordList.seeRecords' | translate" [value]="'all'" data-test="recordFilters">
          <mat-option [value]="'all'" [routerLink]="[]" [queryParams]="{special: undefined, actor: undefined }" queryParamsHandling="merge">{{('filters.all') | translate }}</mat-option>
          <mat-option *ngFor="let filter of filters | sortTranslation: 'filters.'" [value]="filter" [routerLink]="[]" [queryParams]="{special: filter, actor: undefined }" queryParamsHandling="merge">
            {{('filters.' + filter) | translate }}
          </mat-option>
          <mat-option [value]="'manager'" [routerLink]="[]" [queryParams]="{actor: 'manager', special: undefined }" queryParamsHandling="merge">{{('filters.manager') | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="w-sort">
        <app-sortable-select [availableOptions]="availableGroups" [control]="personalizedColumnsControl" i18nPath="recordList.select." [placeholder]="'recordList.personalize' | translate"></app-sortable-select>
      </div>
    </div>
  </div>

  <app-table-no-data [dataSource]="dataSource" [subscription]="subscription" [message]="('recordList.noData' | translate)">
    <div class="shadow-dark overflow-auto whitespace-nowrap border-tb">
      <table mat-table [dataSource]="dataSource.data" multiTemplateDataRows matSort (matSortChange)="sortRecords($event)" class="w-full">
        <!-- Owner Column -->
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef [ngClass]="{'border-right': this.personalizedColumns[0] !== 'owner'}" class="center">{{'recordList.columns.owner' | translate}}</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'border-right': this.personalizedColumns[0] !== 'owner'}">
            <div class="flex items-center justify-center">
              <app-image-avatar [organization]="element.record.organization" [matTooltip]="element.record.organization.id === user.organization?.id ? (('recordList.columns.owner') | translate) : ''" [size]="'s'"></app-image-avatar>
            </div>
          </td>
        </ng-container>

        <!-- Id Column -->
        <ng-container matColumnDef="id" [sticky]="true">
          <th mat-header-cell *matHeaderCellDef class="cell-m">{{'recordList.columns.records' | translate}}</th>
          <td mat-cell *matCellDef="let element" aria-label="expand row" class="cell-m">
            <div class="flex shrink-0">
              <a [routerLink]="['/record-detail', element.record.id, 'canvas']" data-test="recordIdLink" class="flex max-width-75" [matTooltip]="element.record.creatorReference || Helper.getShortId(element.record.id)">
                <span class="link mr-1 truncate">{{element.record.creatorReference || Helper.getShortId(element.record.id)}}</span>
                <span *ngIf="element.record.ownReferences.length > 1" class="truncate link mr-1">
                  <span>| {{element.record.ownReferences[1]}}</span>
                </span>
              </a>
              <mat-icon *ngIf="selectedView === VIEWS_TYPE.MAIN" (click)="toggleRow(element)" svgIcon="{{element.limits.main === 1 ? 'chevron-down' : 'chevron-up'}}" color="primary" class="shrink-0 cursor-pointer" data-test="recordIdChevronIcon"></mat-icon>
            </div>
          </td>
        </ng-container>

        <!-- Links Column -->
        <ng-container matColumnDef="links" [sticky]="true">
          <th mat-header-cell *matHeaderCellDef class="shadow-right px-2.5">{{'recordList.columns.links' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="shadow-right">
            <div class="flex">
              <mat-icon [routerLink]="['/record-detail/', element.record.id, 'activity']" [matTooltip]="('recordList.tooltips.activity') | translate" svgIcon="calendar" color="disabled" class="mr-2 cursor-pointer" data-test="activityLink"></mat-icon>
              <mat-icon [routerLink]="['/record-detail/', element.record.id, 'documents']" [matTooltip]="('recordList.tooltips.documents') | translate" svgIcon="file-text" color="disabled" class="mr-2 cursor-pointer" data-test="documentsLink"></mat-icon>
              <mat-icon [routerLink]="['/record-detail/', element.record.id, 'messages']" [matTooltip]="('recordList.tooltips.messages') | translate" [queryParams]="{ 'filter.recordId': element.record.id }" svgIcon="messages" color="disabled" class="mr-2 cursor-pointer" data-test="messagesLink"></mat-icon>
            </div>
          </td>
        </ng-container>

        <!-- createdAt Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="cell-m border-right">{{'recordList.columns.createdAt' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="cell-m border-right">
            <div class="flex flex-col">
              <span>{{element.record.createdAt | date: 'shortDate' }}</span>
              <span class="actions-a3" [matTooltip]="user.organization?.name || ''">{{user.organization?.name | truncate: 15}}</span>
            </div>
          </td>
        </ng-container>

        <!-- resources Column -->
        <ng-container matColumnDef="resources">
          <th mat-header-cell *matHeaderCellDef class="cell-l">{{'recordList.columns.resources' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="cell-l">
            <ng-template #template let-item="item">
              <span *ngIf="element.resources.length <= 1" class="flex items-center">-</span>
              <a [routerLink]="['/record-detail', element.record.id, 'actors', item.subtype, item.id, 'data']" class="h-10 flex items-center link" [ngClass]="{'disabled-row': item.hasReadingPermissions === false}">
                <div>{{item.name | truncate: 13}}</div>
                <div *ngIf="item.name" class="mx-1">|</div>
                <div>{{'objects.' + item.subtype | translate}}</div>
              </a>
            </ng-template>
            <app-iterable-cell [arr]="element.resources.slice(1)" [template]="template" [limits]="element.limits" [group]="'resources'" [main]="true"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- references Column -->
        <ng-container matColumnDef="references">
          <th mat-header-cell *matHeaderCellDef>{{'recordList.columns.references' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-template #template let-item="item">
              <div class="h-10 flex items-center">{{(item.reference | truncate: 13) || '-' }}</div>
            </ng-template>
            <app-iterable-cell [arr]="element.references" [template]="template" [limits]="element.limits" [group]="'resources'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- myReferences Column -->
        <ng-container matColumnDef="myReferences">
          <th mat-header-cell *matHeaderCellDef class="border-right">{{'recordList.columns.myReferences' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="border-right">
            <ng-template #template let-item="item">
              <div class="h-10 flex items-center">{{(item.reference | truncate: 13) || '-' }}</div>
            </ng-template>
            <app-iterable-cell [arr]="element.ownReferences" [template]="template" [limits]="element.limits" [group]="'resources'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- invoices Column -->
        <ng-container matColumnDef="invoices">
          <th mat-header-cell *matHeaderCellDef class="border-right">{{'recordList.columns.invoices' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="border-right">
            <ng-template #template let-item="item">
              <a [routerLink]="['/record-detail', element.record.id, 'invoices']" class="h-10 flex items-center link" [ngClass]="{'disabled-row': item.hasReadingPermissions === false}">
                <span>{{item | truncate: 13}}</span>
              </a>
            </ng-template>
            <app-iterable-cell [arr]="element.recordInvoices" [template]="template" [limits]="element.limits" [group]="'invoices'" [main]="true"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- buyer Column -->
        <ng-container matColumnDef="buyer">
          <th mat-header-cell *matHeaderCellDef class="border-right">{{'recordList.columns.buyer' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="border-right">
            <div class="flex items-center">
              <span *ngIf="!element.buyerActorData.id">-</span>
              <a [routerLink]="['/record-detail', element.record.id, 'actors', 'buyer', element.buyerActorData.id, 'data']" class="h-10 flex items-center link" [ngClass]="{'disabled-row': element.buyerActorData.hasReadingPermissions === false}">
                <div class="mr-0.5">{{element.buyerActorData.displayName | truncate: 13}}</div>
              </a>
            </div>
          </td>
        </ng-container>

        <!-- supplier Column -->
        <ng-container matColumnDef="supplier">
          <th mat-header-cell *matHeaderCellDef class="border-right">{{'recordList.columns.supplier' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="border-right">
            <div class="flex items-center">
              <span *ngIf="!element.supplierActorData.id">-</span>
              <a [routerLink]="['/record-detail', element.record.id, 'actors', 'supplier', element.supplierActorData.id, 'data']" class="h-10 flex items-center link" [ngClass]="{'disabled-row': element.supplierActorData.hasReadingPermissions === false}">
                <div class="mr-0.5">{{element.supplierActorData.displayName | truncate: 13}}</div>
              </a>
            </div>
          </td>
        </ng-container>

        <!-- pickUp Column -->
        <ng-container *ngIf="displayedColumns.includes('pickUp')" matColumnDef="pickUp">
          <th mat-header-cell *matHeaderCellDef class="border-right">{{'recordList.columns.pickUp' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="border-right">
            <ng-template #template let-item="item">
              <a [routerLink]="['/record-detail', element.record.id, 'places', 'pickup', item.id, 'data']" class="h-10 flex items-center link" [ngClass]="{'disabled-row': item.hasReadingPermissions === false}" data-test="pickUpLink">
                <div class="mr-0.5">{{item.displayName | truncate: 13}}</div>
                <div *ngIf="item.gln || item.locode || item.subdivision" class="mx-1">|</div>
                <div class="mr-0.5">{{(item.gln || item.locode || item.subdivision) | truncate: 13}}</div>
                <div *ngIf="item.country" class="mr-0.5"> ({{item.country}})</div>
              </a>
            </ng-template>
            <app-iterable-cell [arr]="element.recordPickupData" [template]="template" [limits]="element.limits" [group]="'pickUp'" [main]="true" data-test="pickupIterableCell"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- delivery Column -->
        <ng-container matColumnDef="delivery">
          <th mat-header-cell *matHeaderCellDef class="border-right">{{'recordList.columns.delivery' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="border-right">
            <ng-template #template let-item="item">
              <a [routerLink]="['/record-detail', element.record.id, 'places', 'delivery', item.id, 'data']" class="h-10 flex items-center link" [ngClass]="{'disabled-row': item.hasReadingPermissions === false}" data-test="deliveryLink">
                <div class="mr-0.5">{{item.displayName | truncate: 13}}</div>
                <div *ngIf="item.gln || item.locode || item.subdivision" class="mx-1">|</div>
                <div class="mr-0.5">{{(item.gln || item.locode || item.subdivision) | truncate: 13}}</div>
                <div *ngIf="item.country" class="mr-0.5"> ({{item.country}})</div>
              </a>
            </ng-template>
            <app-iterable-cell [arr]="element.recordDeliveryData" [template]="template" [limits]="element.limits" [group]="'delivery'" [main]="true" data-test="deliveryIterableCell"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- origins Column -->
        <ng-container matColumnDef="origin">
          <th mat-header-cell *matHeaderCellDef class="border-right">{{'recordList.columns.origin' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="border-right">
            <ng-template #template let-item="item">
              <a [routerLink]="['/record-detail', element.record.id, 'places', 'origin', item.id, 'data']" class="h-10 flex items-center link" [ngClass]="{'disabled-row': item.hasReadingPermissions === false}">
                <div class="mr-0.5">{{item.displayName | truncate: 13}}</div>
                <div *ngIf="item.gln || item.locode || item.subdivision" class="mx-1">|</div>
                <div class="mr-0.5">{{(item.gln || item.locode || item.subdivision) | truncate: 13}}</div>
                <div *ngIf="item.country" class="mr-0.5"> ({{item.country}})</div>
              </a>
            </ng-template>
            <app-iterable-cell [arr]="element.connectedOrigins" [template]="template" [limits]="element.limits" [group]="'origin'" [main]="true"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- destinations Column -->
        <ng-container matColumnDef="destination">
          <th mat-header-cell *matHeaderCellDef class="border-right">{{'recordList.columns.destination' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="border-right">
            <ng-template #template let-item="item">
              <a [routerLink]="['/record-detail', element.record.id, 'places', 'destination', item.id, 'data']" class="h-10 flex items-center link" [ngClass]="{'disabled-row': item.hasReadingPermissions === false}">
                <div class="mr-0.5">{{item.displayName | truncate: 13}}</div>
                <div *ngIf="item.gln || item.locode || item.subdivision" class="mx-1">|</div>
                <div class="mr-0.5">{{(item.gln || item.locode || item.subdivision) | truncate: 13}}</div>
                <div *ngIf="item.country" class="mr-0.5"> ({{item.country}})</div>
              </a>
            </ng-template>
            <app-iterable-cell [arr]="element.connectedDestinations" [template]="template" [limits]="element.limits" [group]="'destination'" [main]="true"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- documents Column -->
        <ng-container matColumnDef="documents">
          <th mat-header-cell *matHeaderCellDef class="cell-l border-right">{{'recordList.columns.documents' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="cell-l border-right">
            <div [matTooltip]="element.hasReadingPermissions === false ? ('recordList.noPermissions' | translate) : ''" class="grid grid-cols-3 gap-1 p-2">
              <span *ngIf="element.recordDocumentsData.length === 0">-</span>
              <app-chip *ngFor="let document of element.recordDocumentsData.slice(0,8)" [data]="document.alias || ('objects.' + document.documentType | translate )" [color]="'warn'" [iconLeft]="'file-text'" [size]="'small'" [routerLink]="['/record-detail/', element.id, 'documents']" [queryParams]="{ documentId: document.documentId, versionId: document.lastVersionId }" [truncate]="true" [class]="'mr-4'" additionalClasses="link"></app-chip>
              <app-chip *ngIf="element.recordDocumentsData.length > 8" class="cursor-pointer" [data]="'recordDetail.seeAll' | translate" [color]="'warn'" [iconRight]="'chevron-right'" [truncate]="true" [size]="'small'" additionalClasses="link" [routerLink]="['/record-detail/', element.id, 'documents']"></app-chip>
            </div>
          </td>
        </ng-container>

        <!-- transportDocument Column -->
        <ng-container matColumnDef="transportDocument">
          <th mat-header-cell *matHeaderCellDef>{{'recordList.columns.transportDocument' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-template #template let-item="item">
              <div class="h-10 flex items-center">{{item.code || '-'}}</div>
            </ng-template>
            <app-iterable-cell [arr]="element.containersInfo" [template]="template" [limits]="element.limits" [group]="'transport'" [main]="true"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- containers Column -->
        <ng-container matColumnDef="containers">
          <th mat-header-cell *matHeaderCellDef class="cell-l border-right">{{'recordList.columns.containers' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="cell-l border-right">
            <ng-template #template let-item="item">
              <span *ngIf="!item.containersExternalIds || item.containersExternalIds.length === 0" class="h-10 flex items-center">-</span>
              <div *ngIf="item.containersExternalIds" class="grid grid-cols-3 gap-1 p-2">
                <app-chip *ngFor="let container of item.containersExternalIds.slice(0,8)" [data]="container" [additionalClasses]="'label'" [color]="'indigo'" [iconLeft]="'container'" [size]="'small'" [routerLink]="['/record-detail/', element.record.id, 'actors', 'transporter', element.id, 'goods']" [truncate]="true" class="mr-2 cursor-pointer"></app-chip>
                <app-chip *ngIf="item.containersExternalIds.length > 8" [data]="'recordDetail.seeAll' | translate" [additionalClasses]="'label'" [color]="'indigo'" [iconRight]="'chevron-right'" [size]="'small'" [routerLink]="['/record-detail/', element.record.id, 'actors', 'transporter', element.id, 'goods']" class="cursor-pointer"></app-chip>
              </div>
            </ng-template>
            <app-iterable-cell [arr]="element.containersInfo" [template]="template" [limits]="element.limits" [group]="'transport'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- taxes Column -->
        <ng-container matColumnDef="taxes">
          <th mat-header-cell *matHeaderCellDef>{{'recordList.columns.taxes' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-template #template let-item="item">
              <a [routerLink]="['/record-detail', element.record.id, 'actors', 'taxes', item.id, 'data']" class="h-10 flex items-center link" [ngClass]="{'disabled-row': item.hasReadingPermissions === false}" data-test="taxesLink">{{item.displayName}}</a>
            </ng-template>
            <app-iterable-cell [arr]="element.recordTaxesData" [template]="template" [limits]="element.limits" [group]="'taxes'" [main]="true" data-test="taxesIterableCell"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- mrn Column -->
        <ng-container matColumnDef="mrn">
          <th mat-header-cell *matHeaderCellDef>{{'recordList.columns.mrn' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-template #template let-item="item">
              <div class="h-10 flex items-center">{{(item.customsNumber | truncate: 18) || '-'}}</div>
            </ng-template>
            <app-iterable-cell [arr]="element.recordTaxesData" [template]="template" [limits]="element.limits" [group]="'taxes'"></app-iterable-cell>
          </td>
        </ng-container>
        .
        <!-- status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>{{'recordList.columns.status' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-template #template let-item="item">
              <div class="flex items-center h-10 space-x-2">
                <span *ngIf="!item.customsClearance || item.customsClearance === 'none'">-</span>
                <app-chip *ngIf="item.customsClearance" [data]="'select.customsClearance.' + item.customsClearance | translate" [color]="item.customsClearance" [additionalClasses]="'label uppercase'" [size]="'small'"></app-chip>
              </div>
            </ng-template>
            <app-iterable-cell [arr]="element.recordTaxesData" [template]="template" [limits]="element.limits" [group]="'taxes'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- transportName Column -->
        <ng-container matColumnDef="transportName">
          <th mat-header-cell *matHeaderCellDef>{{'recordList.columns.transportName' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-template #template let-item="item">
              <a [routerLink]="['/record-detail', element.record.id, 'actors', 'transporter', item.id, 'data']" class="h-10 flex items-center link" data-test="transportLink" [ngClass]="{'disabled-row': item.hasReadingPermissions === false}">{{(item.displayName | truncate: 20)}}</a>
            </ng-template>
            <app-iterable-cell [arr]="element.recordTransportData" [template]="template" [limits]="element.limits" [group]="'transport'" [main]="true" data-test="transportIterableCell"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- transportType Column -->
        <ng-container matColumnDef="transportTypes">
          <th mat-header-cell *matHeaderCellDef class="border-right">{{'recordList.columns.transportType' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="border-right">
            <ng-template #template let-item="item">
              <div *ngIf="item.type === TRANSPORT_TYPES.None" class="h-10 flex items-center">-</div>
              <div *ngIf="item.type !== TRANSPORT_TYPES.None" class="h-10 flex items-center">
                <mat-icon [svgIcon]="'transport-'+ item.type" [matTooltip]="('select.transportType.' + item.type) | translate" data-test="transportIcon"></mat-icon>
              </div>
            </ng-template>
            <app-iterable-cell [arr]="element.recordTransportData" [template]="template" [limits]="element.limits" [group]="'transport'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- transportId Column -->
        <ng-container matColumnDef="transportId">
          <th mat-header-cell *matHeaderCellDef>{{'recordList.columns.transportId' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-template #template let-item="item">
              <div class="h-10 flex items-center">{{item.vehicleCode || '-' }}</div>
            </ng-template>
            <app-iterable-cell [arr]="element.recordTransportData" [template]="template" [limits]="element.limits" [group]="'transport'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- tact Column -->
        <ng-container matColumnDef="tact">
          <th mat-header-cell *matHeaderCellDef class="cell-l border-right">{{ 'recordList.columns.tact' | translate }}</th>
          <td mat-cell *matCellDef="let element" class="cell-l border-right">
            <ng-template #template let-item="item">
              <span *ngIf="!item.tact || item.tact[0] === null" class="h-10 flex items-center">-</span>
              <div *ngIf="item.tact && item.tact[0] !== null" class="grid grid-cols-3 gap-1 p-2">
                <app-chip *ngFor="let tact of item.tact.slice(0,8)" [data]="tact" [additionalClasses]="'label'" [color]="'grey'" [size]="'small'" class="mr-2"></app-chip>
                <app-chip *ngIf="item.tact.length > 8" [data]="'recordDetail.seeAll' | translate" [additionalClasses]="'label'" [color]="'grey'" [iconRight]="'chevron-right'" [size]="'small'" [routerLink]="['/record-detail/', element.record.id, 'actors', 'transporter', element.id, 'goods']" class="cursor-pointer"></app-chip>
              </div>
            </ng-template>
            <app-iterable-cell [arr]="element.containersInfo" [template]="template" [limits]="element.limits" [group]="'transport'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- departureEstimated Column -->
        <ng-container matColumnDef="departureEstimated">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'recordList.columns.etd' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-template #template let-item="item">
              <ng-container *ngTemplateOutlet="dateTemplate;context:{item: item.departureEstimated}"></ng-container>
            </ng-template>
            <app-iterable-cell [arr]="element.recordTransportData" [template]="template" [limits]="element.limits" [group]="'transport'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- departureActual Column -->
        <ng-container matColumnDef="departureActual">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'recordList.columns.atd' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-template #template let-item="item">
              <ng-container *ngTemplateOutlet="dateTemplate;context:{item: item.departureActual}"></ng-container>
            </ng-template>
            <app-iterable-cell [arr]="element.recordTransportData" [template]="template" [limits]="element.limits" [group]="'transport'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- arrivalEstimated Column -->
        <ng-container matColumnDef="arrivalEstimated">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>{{'recordList.columns.eta' | translate}}</th>
          <td mat-cell *matCellDef="let element">
            <ng-template #template let-item="item">
              <ng-container *ngTemplateOutlet="dateTemplate;context:{item: item.arrivalEstimated}"></ng-container>
            </ng-template>
            <app-iterable-cell [arr]="element.recordTransportData" [template]="template" [limits]="element.limits" [group]="'transport'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- arrivalActual Column -->
        <ng-container matColumnDef="arrivalActual">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="border-right">{{'recordList.columns.ata' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="border-right">
            <ng-template #template let-item="item">
              <ng-container *ngTemplateOutlet="dateTemplate;context:{item: item.arrivalActual}"></ng-container>
            </ng-template>
            <app-iterable-cell [arr]="element.recordTransportData" [template]="template" [limits]="element.limits" [group]="'transport'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- statusDate Column -->
        <ng-container matColumnDef="statusDate">
          <th mat-header-cell *matHeaderCellDef class="border-right">{{'recordList.columns.statusDate' | translate}}</th>
          <td mat-cell *matCellDef="let element" [matTooltip]="element.hasReadingPermissions === false ? ('recordList.noPermissions' | translate) : ''" class="border-right">
            <ng-template #template let-item="item">
              <div class="flex items-center h-10">{{item.customsClearanceDate ? (item.customsClearanceDate | date: 'shortDate') : '-'}}</div>
            </ng-template>
            <app-iterable-cell [arr]="element.recordTaxesData" [template]="template" [limits]="element.limits" [group]="'taxes'"></app-iterable-cell>
          </td>
        </ng-container>

        <!-- lastEvent Column -->
        <ng-container matColumnDef="lastEvent">
          <th mat-header-cell *matHeaderCellDef class="border-right">{{'recordList.columns.lastEvent' | translate}}</th>
          <td mat-cell *matCellDef="let element" [matTooltip]="element.hasReadingPermissions === false ? ('recordList.noPermissions' | translate) : ''" class="border-right">
            <div *ngIf="!element.lastEventData">-</div>
            <div *ngIf="element.lastEventData">
              <div class="flex items-center">
                <div *ngIf="element.lastEventData.subtype === 'issue'" class="flex items-center">
                  <mat-icon svgIcon="warning" class="mr-1 warn-icon"></mat-icon>
                  <div class="label block my-1 mr-1">{{'recordList.issue' | translate}} |</div>
                </div>
                <div class="label">{{element.lastEventData.name}}</div>
              </div>
              <div class="block overline-o3 grey my-1">{{element.lastEventData.date | date: 'short'}}</div>
            </div>
          </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="action" [stickyEnd]="true">
          <th mat-header-cell *matHeaderCellDef class="cell-xs shadow">{{'recordList.columns.actions' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="shadow relative cell-xs">
            <div class="space-x-2 absolute left-auto top-4 bottom-auto">
              <mat-icon *ngIf="element.record.roles.includes(ROLES.Creator)" (click)="duplicateRecord(element.record.id)" svgIcon="copy" [color]="'primary'" data-test="cloneRecordButton" class="cursor-pointer" [matTooltip]="'recordList.tooltips.duplicateRecord' | translate"></mat-icon>
              <mat-icon *ngIf="element.record.roles.includes(ROLES.Creator)" (click)="openSaveTemplateModal(element.record.id); $event.stopPropagation()" svgIcon="template" [color]="'primary'" data-test="saveToTemplateButton" class="cursor-pointer" [matTooltip]="'recordList.tooltips.saveAsTemplate' | translate"></mat-icon>
              <mat-icon *ngIf="element.record.perms.includes(PERMISSION.updateStatus)" (click)="archiveRecord(element.record.id); $event.stopPropagation()" [svgIcon]="archived ? 'unarchive' : 'archive'" [color]="'primary'" data-test="archiveRecordButton" class="cursor-pointer" [matTooltip]="'recordList.tooltips.' + (archived ? 'unarchive' : 'archive') | translate"></mat-icon>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns;"></tr>
      </table>
    </div>

    <div [ngClass]="{'border-paginator--empty': !dataSource.data.length}" class="flex justify-between border-paginator bg-color overflow-auto whitespace-nowrap mb-4">
      <app-usage-progress-bar *ngIf="limit" [type]="'records'" [limit]="limit" class="w-1/3 pt-2 px-2 bg-grey-100"></app-usage-progress-bar>
      <mat-paginator (page)="getPage($event)" [length]="totalElements" [pageSize]="query.pagination!.limit" [pageSizeOptions]="PAGE_SIZE_OPTIONS" showFirstLastButtons [ngClass]="{'hidden': !dataSource.data.length}" class="w-full"></mat-paginator>
    </div>
  </app-table-no-data>
</section>

<!--Esta aqui abajo para que sea reutilizable-->
<ng-template #dateTemplate let-item="item">
  <div *ngIf="!item || !item.date" class="my-2 mr-1">-</div>
  <div>
    <div *ngIf="item && item.date" [matTooltip]="item?.timeZone || ''" class="label my-2 mr-1">{{item.date  | date:'shortDate'}}</div>
    <div *ngIf="item && item.time" class="mr-1 overline-o3 grey">{{item.time}}</div>
  </div>
</ng-template>
